
import {defineComponent, onMounted, ref} from "vue";
import {useRoute, useRouter} from 'vue-router';

interface IParams {
    OrderID: string;
    HashData: string;
    TerminalID: string;
    MerchantID: string;
    TerminalProvUserID: string;
    TerminalUserID: string;
    InstallmentCount: string;
    Amount : number;
    Cur: string;
    CompanyName: string;
    Description: string;
    CardHolderName: string;
    CardNumber: string;
    CardExpiryMonth: string;
    CardExpiryYear: string;
    CardCvv: string;
    SuccessUrl: string;
    ErrorUrl: string;
}

export default defineComponent({
    name: "pos-garanti-form",
    setup() {

        var theParams = ref<IParams>({
            OrderID: "",
            HashData: "",
            TerminalID: "",
            MerchantID: "",
            TerminalProvUserID:"",
            TerminalUserID: "",
            InstallmentCount: "0",
            Amount: 0,
            Cur: "",
            CompanyName: "",
            Description: "",
            CardHolderName: "",
            CardNumber: "",
            CardExpiryMonth: "",
            CardExpiryYear: "",
            CardCvv: "",
            SuccessUrl: "",
            ErrorUrl: "",
        });

        onMounted(()=>{
            let urlParams = new URLSearchParams(location.href);
            //console.log("URL Params orderid: "+urlParams.has('orderid')+" "+urlParams.get('orderid')); // true
            console.log("PosGarantiForm URL Params orderid: "+getParamByName("orderid", location.href));
            console.log("PosGarantiForm URL Params hashdata: "+getParamByName("hashdata", location.href));
            theParams.value.OrderID = getParamByName("orderid", location.href);
            theParams.value.HashData = getParamByName("hashdata", location.href);
            theParams.value.TerminalID = getParamByName("TerminalID", location.href);
            theParams.value.MerchantID = getParamByName("MerchantID", location.href);
            theParams.value.TerminalProvUserID = getParamByName("TerminalProvUserID", location.href);
            theParams.value.TerminalUserID = getParamByName("TerminalUserID", location.href);
            theParams.value.InstallmentCount = getParamByName("InstallmentCount", location.href);
            theParams.value.Amount = Number(getParamByName("amount", location.href));
            theParams.value.Cur = getParamByName("cur", location.href);
            theParams.value.CompanyName = getParamByName("companyname", location.href);
            theParams.value.Description = getParamByName("description", location.href);
            theParams.value.CardHolderName = getParamByName("cardholdername", location.href);
            theParams.value.CardNumber = getParamByName("cardnumber", location.href);
            theParams.value.CardExpiryMonth = getParamByName("cardexpirymonth", location.href);
            theParams.value.CardExpiryYear = getParamByName("cardexpiryyear", location.href);
            theParams.value.CardCvv = getParamByName("cardcvv", location.href);
            theParams.value.SuccessUrl= getParamByName("successurl", location.href);
            theParams.value.ErrorUrl= getParamByName("errorurl", location.href);

            setTimeout(submitForm, 1000);
        });

        function getParamByName(name, url = window.location.href) {
            //name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

        function click_TestMes() {
            window.parent.postMessage("POS_RESULT");
        }

        function click_TestSubmit() {
            submitForm();
        }

        function submitForm() {
            let frmPosGaranti = document.getElementById("frmPosGaranti") as HTMLFormElement;
            frmPosGaranti.submit();
        }

        return {
            click_TestSubmit,
            click_TestMes, theParams,
        };
    }
});
